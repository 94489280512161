import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  deleteFavContact,
  deleteFavDevelopment,
  deleteFavProperty,
  deleteFavSearch,
  exportToXls,
  getFavoritesContacts,
  getFavoritesDevelopments,
  getFavoritesProperties,
  getFavoritesSearchs,
  getPagesForExportPdf,
} from './services';

// Actual Slice
export const FavoritesSlice = createSlice({
  name: 'Favorites',
  initialState,
  reducers: {
    setFavoritesData(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    handleOneFavorite(state, action) {
      const { tab, id } = action.payload;

      const tabs = {
        contacts: state.contacts,
        properties: state.properties,
        developments: state.developments,
        searchs: state.searchs,
      };

      const targetTab = tabs[tab];
      const modifiedTab = targetTab.map((el) =>
        el.id === id ? { ...el, checked: !el.checked } : el
      );

      state[tab] = modifiedTab;
    },
    handleAllFavorites(state, action) {
      const tab = action.payload;
      const tabs = {
        contacts: state.contacts,
        properties: state.properties,
        developments: state.developments,
        searchs: state.searchs,
      };

      const targetTab = tabs[tab];
      const checkedValueForAll = targetTab.some((el) => !el.checked);

      const modifiedTab = targetTab.map((el) => ({
        ...el,
        checked: checkedValueForAll,
      }));

      state[tab] = modifiedTab;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFavoritesSearchs.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFavoritesSearchs.fulfilled, (state, action) => {
      Object.assign(state, {
        isLoading: false,
        searchs: action.payload.searches.map((search) => ({
          ...search,
          checked: false,
        })),
      });
    });

    builder.addCase(getFavoritesContacts.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFavoritesContacts.fulfilled, (state, action) => {
      Object.assign(state, {
        isLoading: false,
        contacts: action.payload.contacts.map((contact) => ({
          ...contact,
          checked: false,
        })),
      });
    });

    builder.addCase(getFavoritesProperties.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFavoritesProperties.fulfilled, (state, action) => {
      Object.assign(state, {
        isLoading: false,
        properties: action.payload.properties.map((property) => ({
          ...property,
          checked: false,
        })),
      });
    });

    builder.addCase(getFavoritesDevelopments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFavoritesDevelopments.fulfilled, (state, action) => {
      Object.assign(state, {
        isLoading: false,
        developments: action.payload.developments.map((development) => ({
          ...development,
          checked: false,
        })),
      });
    });

    builder.addCase(deleteFavSearch.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.searchs = state.searchs.filter((search) => search.id !== id);
    });

    builder.addCase(deleteFavContact.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.contacts = state.contacts.filter((contact) => contact.id !== id);
    });

    builder.addCase(deleteFavProperty.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.properties = state.properties.filter(
        (property) => property.id !== id
      );
    });

    builder.addCase(deleteFavDevelopment.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.developments = state.developments.filter(
        (development) => development.id !== id
      );
    });

    builder.addCase(getPagesForExportPdf.fulfilled, (state, action) => {
      Object.assign(state, {
        isLoading: false,
        listPagesPdf: action.payload.pdf_types,
      });
    });

    builder.addCase(exportToXls.fulfilled, (_, action) => {
      const blob = action.payload;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const date = new Date();
      const dateTransform = date.toJSON().split('T')[0];
      link.setAttribute(
        'download',
        `${Trans(
          translations,
          'favoritesComponent.NameExcelFile',
          dateTransform
        )}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
});

export const { setFavoritesData, handleOneFavorite, handleAllFavorites } =
  FavoritesSlice.actions;

export default FavoritesSlice.reducer;
